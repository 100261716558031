import $ from 'jquery';

$(() => {
  /**
 * 離脱防止モーダル
 */
  let currentHeight = 0;

 // 離脱防止モーダル表示
 const leaveModal = () => {
   const posi = $(window).scrollTop() || 0; //モーダル表示中、背景がスクロールされないよう固定
   currentHeight = posi;
   $('.pg-pointArea,.p-overlay').show();
   $('html').css({
     width: '100%',
     position: 'fixed',
     top: -1 * posi
   });
 };

 history.pushState(null, '', null);
 $(window).one('popstate', () => leaveModal());
 $('a').off('popstate');

 $('.p-modal__close, .p-overlay').on('click', () => {
   $('.p-modal,.p-overlay').hide();
   $('html').attr('style', ''); //背景スクロール固定解除
   $('html, body').prop({ scrollTop: currentHeight }); //位置を戻す
   return false;
 });
  $('a[href^="#"]').click(function () {
    const href = $(this).attr('href') || '';
    const target = $(href == '#' || href == '' ? 'html' : href);
    const position = (target.offset()?.top || 0)
    $('html,body').animate({scrollTop:position}, 800, 'swing')
    return false
  });

})
